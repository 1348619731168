import { Component } from '@angular/core';
import {SpaUpdateService} from "./shared/spa-update.service";

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent {

    constructor(spaService: SpaUpdateService) {}
}
