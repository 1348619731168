import {ActivatedRoute, Router} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable()
export class CognitoAuthRouterService {

    constructor(private router: Router) {}

    navigateToSignedInRedirect(route: ActivatedRoute) {
        const redirectURL = route.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
        this.router.navigateByUrl(redirectURL);
    }

    navigateToSignIn() {
        this.router.navigate(['sign-in'],  {
            queryParamsHandling: 'merge',
            state: { internalRouting: true }
        });
    }

    navigateToImpersonateUser() {
        this.router.navigate(['impersonate-user'],  {
            queryParamsHandling: 'merge',
            state: { internalRouting: true }
        });
    }

    navigateToNewPasswordRequired() {
        this.router.navigate(['new-password-required'],  {
            queryParamsHandling: 'merge',
            state: { internalRouting: true }
        });
    }

    navigateToPasswordResetConfirmation() {
        this.router.navigate(['password-reset-confirmation'],  {
            queryParamsHandling: 'merge',
            state: { internalRouting: true }
        });
    }

    navigateToSignUpComplete() {
        this.router.navigate(['sign-up-complete'],  {
            queryParamsHandling: 'merge',
            state: { internalRouting: true }
        });
    }

    navigateToForgotPasswordSubmit(email: string) {
        this.router.navigate(['forgot-password-submit'], {
            queryParamsHandling: 'merge',
            state: { internalRouting: true },
            queryParams: { email }
        });
    }

    navigateToConfirmSignUp(email: string) {
        this.router.navigate(['confirm-sign-up'], {
            queryParamsHandling: 'merge',
            state: { internalRouting: true },
            queryParams: { email }
        });
    }
}

