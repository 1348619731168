import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiMetadata, Chunk, GetChunkRequest, GetChunkResponse, PutChunkRequest, PutChunkResponse} from "./fx8.types";
import {first, forkJoin, map, Observable, of, switchMap} from "rxjs";
import {Buffer} from "buffer";
import {GeneralUtils} from "./general-util";


export interface GenericApiRequest {
    apiMetadata?: ApiMetadata;
    getChunkRequest?: GetChunkRequest;
    putChunkRequest?: PutChunkRequest;
}

export interface GenericApiResponse {
    apiMetadata?: ApiMetadata;
    getChunkResponse?: GetChunkResponse;
    putChunkResponse?: PutChunkResponse;
}



@Injectable({
    providedIn: 'root'
})
export class ApiSupportService {

    constructor(private httpClient: HttpClient) {}

    public post(url: string, request: GenericApiRequest): Observable<GenericApiResponse> {
        const requestData = JSON.stringify(request);
        const size = Buffer.byteLength(requestData, 'utf8')
        const maxSizeInBytes = 2 * Math.pow(1024, 2);
        if (!request.putChunkRequest && size > maxSizeInBytes) {
            const factor = maxSizeInBytes / size;
            const stringChunkSize = Math.floor(requestData.length * factor);
            const chunkDataList = this.splitIntoChunks(requestData, stringChunkSize);
            const chunkList = chunkDataList.map(data => <Chunk>{ id: GeneralUtils.generateId(), data });
            const chunks$ = chunkList.map(chunk => {
                return this.post(url,{ putChunkRequest: { chunk } }).pipe(
                    map(() => {})
                );
            });
            return forkJoin(chunks$).pipe(
                switchMap(_ => {
                    return this.post(url,{
                        apiMetadata: {
                            chunkId: chunkList.map(c => c.id)
                        }
                    });
                })
            );
        }
        else {
            return this.httpClient.post(url, request).pipe(
                map(res => <GenericApiResponse>res),
                switchMap(response => {
                    if (response.apiMetadata?.chunkId?.length > 0) {
                        const chunks$ = response.apiMetadata.chunkId.map(id => {
                            return this.post(url,{ getChunkRequest: { id } }).pipe(
                                map(res => res.getChunkResponse.chunk.data)
                            );
                        });
                        const join$ = forkJoin(chunks$);
                        return join$.pipe(
                            map(dataArr => dataArr.join('')),
                            map(data => <GenericApiResponse>JSON.parse(data))
                        );
                    }
                    else return of(response);
                }),
                first()
            );
        }
    }

    private splitIntoChunks(str: string, length: number) {
        return str.match(new RegExp('.{1,' + length + '}', 'g'));
    }
}
