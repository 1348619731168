import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable, of, switchMap, tap} from 'rxjs';
import {CognitoAuthService} from "./cognito-auth.service";

@Injectable({
    providedIn: 'root'
})
export class CognitoAuthGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private authService: CognitoAuthService,
        private _router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this.check(redirectUrl);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this.check(redirectUrl);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.check('/');
    }

    private check(redirectURL: string): Observable<boolean> {
        return this.authService.isSignedIn().pipe(
            switchMap(signedIn => {
                if (signedIn) {
                    return of(true);
                }
                else {
                    this._router.navigate(['sign-in'], {queryParams: {redirectURL}});
                    return of(false);
                }
            })
        );
    }
}
