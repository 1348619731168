<button
        mat-icon-button
        [matMenuTriggerFor]="userActions">
    <span class="relative">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full"></span>
    </span>
</button>

<mat-menu
        [xPosition]="'before'"
        #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="text-sm font-semibold">
                {{ 'general.authenticated-as' | transloco }}
            </span>
            <span class="mt-1.5 text-md font-medium text-primary font-bold">{{ (user$ | async)?.name }}</span>
        </span>
    </button>


    <ng-container *ngIf="impersonateUser$ && impersonateUser$ | async as impersonateUser">
        <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="text-sm font-semibold">
                {{ 'general.impersonated-as' | transloco }}
            </span>
            <span class="mt-1.5 text-md font-medium text-primary font-bold">{{ impersonateUser?.name }}</span>
        </span>
        </button>

    </ng-container>

    <ng-container *ngIf="user$ && (user$ | async).admin">
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="onImpersonation()">
            <mat-icon [svgIcon]="'heroicons_outline:users'"></mat-icon>
            <span>{{ 'general.impersonation' | transloco }}</span>
        </button>
    </ng-container>

    <mat-divider class="my-2"></mat-divider>

    <button mat-menu-item (click)="onSettings()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>{{ 'general.settings' | transloco }}</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <button
            mat-menu-item
            (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{ 'general.sign-out' | transloco }}</span>
    </button>
</mat-menu>
