/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'main',
        title   : 'Dashboard',
        subtitle: 'Management',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'main.home',
                title: 'home',
                type : 'basic',
                icon : 'home',
                link : '/main/home'
            },
            {
                id   : 'main.pool-performance',
                title: 'Performance',
                type : 'basic',
                icon : 'heroicons_outline:chart-bar',
                hidden: _ => true,
                link : '/main/pool-report/performance',
            },
            {
                id   : 'main.pool-account-report',
                title: 'Account',
                type : 'basic',
                icon : 'account_balance_wallet',
                link : '/main/pool-report/account',
                exactMatch: true
            },
            {
                id   : 'main.pool-deposit',
                title: 'Deposits',
                type : 'basic',
                icon : 'heroicons_outline:plus-circle',
                link : '/main/pool-report/account/deposit-list',
                exactMatch: true
            },
            {
                id   : 'main.pool-withdrawal',
                title: 'Withdrawals',
                type : 'basic',
                icon : 'heroicons_outline:minus-circle',
                link : '/main/pool-report/account/withdrawal-list',
                exactMatch: true
            },
            {
                id   : 'main.bank-account',
                title: 'Bancos',
                type : 'basic',
                icon : 'heroicons_outline:library',
                link : '/main/pool-report/account/withdrawal-list/bank-account-list'
            },
            {
                id   : 'main.account-report',
                title: 'Accounts',
                type : 'basic',
                hidden: _ => true,
                icon : 'account_balance_wallet',
                link : '/main/account-report'
            },
            {
                id   : 'main.simulator',
                title: 'Simulator',
                type : 'basic',
                icon : 'trending_up',
                link : '/main/simulator'
            },
            {
                id   : 'main.settings',
                title: 'Settings',
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : '/main/settings'
            },
            {
                id      : 'main.help-center',
                title   : 'Help Center',
                type    : 'collapsable',
                icon    : 'heroicons_outline:support',
                link      : '/main/help-center',
                children: [
                    {
                        id        : 'main.help-center.home',
                        title     : 'Home',
                        type      : 'basic',
                        link      : '/main/help-center',
                        exactMatch: true
                    },
                    {
                        id   : 'main.help-center.faqs',
                        title: 'FAQs',
                        type : 'basic',
                        link : '/main/help-center/faqs'
                    },
                    {
                        id   : 'main.help-center.guides',
                        title: 'Guides',
                        type : 'basic',
                        link : '/main/help-center/guides'
                    },
                    {
                        id   : 'main.help-center.support',
                        title: 'Support',
                        type : 'basic',
                        link : '/main/help-center/support'

                    }
                ]
            }
        ]
    },
    {
        id      : 'internal',
        title   : 'Internal',
        subtitle: 'Management',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'internal.simulator',
                title: 'Simulator',
                type : 'basic',
                icon : 'trending_up',
                link : '/internal/simulator'
            },
            {
                id   : 'internal.pool',
                title: 'Pool',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/internal/pool-report'
            }
        ]
    },
    {
        id      : 'backoffice',
        title   : 'Backoffice',
        subtitle: 'Management',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'backoffice.pool',
                title: 'Pool',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/backoffice/pool-report'
            }
        ]
    },
    {
        id      : 'private',
        title   : 'Private',
        subtitle: 'Management',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'private.simulator',
                title: 'Simulator',
                type : 'basic',
                icon : 'trending_up',
                link : '/private/simulator'
            },
            {
                id   : 'private.account-report',
                title: 'Accounts',
                type : 'basic',
                hidden: _ => true,
                icon : 'account_balance_wallet',
                link : '/private/account-report'
            },
            {
                id   : 'private.account-performance',
                title: 'Accounts',
                type : 'basic',
                hidden: _ => true,
                icon : 'heroicons_outline:chart-bar',
                link : '/private/account-performance'
            }
        ]
    },
    {
        id      : 'admin',
        title   : 'Admin',
        subtitle: 'Management',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'admin.user',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user',
                link : '/master/user'
            },
            {
                id   : 'admin.user-profile',
                title: 'User Profiles',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/master/user-profile',
                hidden: _ => true
            },
            {
                id   : 'admin.fx8-account',
                title: 'Accounts',
                type : 'basic',
                icon : 'account_balance_wallet',
                link : '/master/fx8-account',
                hidden: _ => true
            },
            {
                id   : 'admin.config',
                title: 'Configs',
                type : 'basic',
                icon : 'heroicons_outline:adjustments',
                link : '/master/config'
            },
            {
                id   : 'admin.contract-model',
                title: 'Contract models',
                type : 'basic',
                icon : 'heroicons_solid:template',
                link : '/master/contract-model'
            },
            {
                id   : 'admin.agreement',
                title: 'Agreements',
                type : 'basic',
                icon : 'grading',
                link : '/master/agreement'
            }
        ]
    }
];


export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'home',
        title: 'Home',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/home'
    },
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
