import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import {CognitoAuthService} from "./cognito-auth.service";

@Injectable({
    providedIn: 'root'
})
export class InvitationGuard implements CanActivate {

    constructor(
        private authService: CognitoAuthService,
        private _router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        // console.log('invitation guard');
        //
        // console.log(JSON.stringify(route.queryParamMap));


        return true;


        // return this.authService.isSignedIn().pipe(
        //     switchMap(signedIn => {
        //         if (signedIn) {
        //             this._router.navigate(['']);
        //             return of(false);
        //         }
        //         else {
        //             return of(true);
        //         }
        //     })
        // );
    }
}
