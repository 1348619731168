import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {filter, first, map, Observable, of, ReplaySubject, switchMap} from 'rxjs';
import {UserService} from 'app/core/user/user.service';

import {UserService as ApiUserService } from 'app/modules/admin/master/user/user.service';

import {CognitoAuthService} from "../../../core/cognito-auth/cognito-auth.service";
import {User} from "../../../shared/fx8.types";


@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    // encapsulation  : ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit {

    @Input() showAvatar: boolean = false;
    user$: Observable<User>;

    impersonateUserSubject = new ReplaySubject<User>(1);
    impersonateUser$: Observable<User>;

    constructor(
        private userService: UserService,
        private authService: CognitoAuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private apiUserService: ApiUserService
    ) {}

    ngOnInit(): void {
        this.authService.getUser().subscribe(u => {});
        this.user$ = this.userService.user$;

        this.impersonateUser$ = this.user$.pipe(
            switchMap(user => {
                if (user.admin) {
                    return this.apiUserService.getImpersonateUser().pipe(
                        switchMap(res => {
                            if (res.enabled) {
                                return this.apiUserService.getUser(res.impersonateUserId);
                            }
                            else {
                                return null;
                            }
                        })
                    );
                }
                else {
                    return null;
                }
            })
        );
    }

    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    onSettings(): void {
        this._router.navigate(['/main/settings']);
    }

    onImpersonation(): void {
        this._router.navigate(['/master/impersonation']);
    }

}
