import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, tap} from 'rxjs';
import {ApiService} from "../../shared/api.service";
import {User, UserProfileConfig} from "../../shared/fx8.types";
import {ConfigQueryService} from "../../shared/config-query.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private userProfileConfig: ReplaySubject<UserProfileConfig> = new ReplaySubject<UserProfileConfig>(1);

    private loaded = false;

    constructor(private apiService: ApiService, private configQueryService: ConfigQueryService) {}

    set user(value: User) {
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get userProfileConfig$(): Observable<UserProfileConfig> {
        if (this.loaded) {
            return this.userProfileConfig.asObservable();
        }
        else {
            return this.loadUserProfileConfig();
        }
    }

    loadUserProfileConfig(): Observable<UserProfileConfig> {
        return this.configQueryService.getUserProfileConfig().pipe(
            tap(userProfileConfig => {
                this.userProfileConfig.next(userProfileConfig);
                this.loaded = true;
            })
        );
    }
}
