import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {map, Observable} from "rxjs";
import {UserProfileConfig} from "./fx8.types";

@Injectable({
    providedIn: 'root'
})
export class ConfigQueryService {

    constructor(private apiService: ApiService) {}

    public getUserProfileConfig(): Observable<UserProfileConfig> {
        return this.apiService.getUserProfileConfig({}).pipe(map(res => res.userProfileConfig));
    }
}
