import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {CognitoAuthRouterService} from "./cognito-auth-router.service";

@Injectable({
    providedIn: 'root'
})
export class InternalRoutingGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private authRouterService: CognitoAuthRouterService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.check();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check();
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.check();
    }

    private check(): Observable<boolean> {
        const status = this.router.getCurrentNavigation().extras.state
            && this.router.getCurrentNavigation().extras.state['internalRouting'] === true;
        if (status) {
            return of(true);
        }
        else {
            this.authRouterService.navigateToSignIn();
            return of(false);
        }
    }
}
