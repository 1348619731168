import {Injectable} from '@angular/core';
import {getBrowserLang, TranslocoService} from "@ngneat/transloco";
import {map, Observable} from "rxjs";
import {LangText} from "./fx8.types";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    constructor(private translocoService: TranslocoService) {}

    translate(text: LangText): Observable<string> {
        return this.translocoService.langChanges$.pipe(
            map(lang => lang === 'pt' && text.pt ? text.pt : text.en)
        );
    }

    getBrowserLanguage(): string {
        let lang = getBrowserLang();
        const availableLangs: string[] = this.translocoService.getAvailableLangs().map(e => e.id);
        const found = availableLangs.find(l => l === lang) !== undefined;
        if (!found) lang = 'en';
        return lang;
    }
}

