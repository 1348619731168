import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
    providedIn: 'root'
})
export class CustomTitleStrategy extends TitleStrategy {

    constructor(
        private translocoService: TranslocoService,
        private readonly title: Title) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot): void {
    }
}

