import { NgModule } from '@angular/core';
import {LogoComponent} from "./logo.component";
import {SharedModule} from "../../../shared/shared.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    declarations: [
        LogoComponent
    ],
    imports     : [
        SharedModule,
        MatButtonModule
    ],
    exports     : [
        LogoComponent
    ]
})
export class LogoModule {}
