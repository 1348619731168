import {Injectable} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../@fuse/services/confirmation";
import {first, map, Observable, switchMap, zip} from "rxjs";


export interface ConfirmRemovalOptions {
    questionKey?: string;
    questionParams?: any;
}

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private confirmationService: FuseConfirmationService,
        private translocoService: TranslocoService
    ) {}

    confirm(questionKey: string): Observable<boolean> {
        const title$ = this.translocoService.selectTranslate('general.confirmation').pipe(first(), map(t => <string>t));
        const question$ = this.translocoService.selectTranslate(questionKey).pipe(first(), map(t => <string>t));
        const ok$ = this.translocoService.selectTranslate('general.ok').pipe(first(), map(t => <string>t));
        const cancel$ = this.translocoService.selectTranslate('general.cancel').pipe(first(), map(t => <string>t));
        return zip([title$, question$, ok$, cancel$]).pipe(
            switchMap(([ title, question, remove, cancel ]) => {
                return this.confirmationService.open({
                    title: title,
                    message: question,
                    icon: {
                        "show": true,
                        "name": "heroicons_outline:exclamation",
                        "color": "warn"
                    },
                    actions: {
                        confirm: {
                            show: true,
                            label: remove,
                            color: "warn"
                        },
                        cancel: {
                            show: true,
                            label: cancel
                        }
                    }
                }).afterClosed()
            }),
            map(res => res === 'confirmed')
        );

    }

    confirmDeletion(item: string): Observable<boolean> {
        const title$ = this.translocoService.selectTranslate('general.confirmation').pipe(first(), map(t => <string>t));
        const question$ = this.translocoService.selectTranslate('dialog.delete-question', { item }).pipe(first(), map(t => <string>t));
        const remove$ = this.translocoService.selectTranslate('general.remove').pipe(first(), map(t => <string>t));
        const cancel$ = this.translocoService.selectTranslate('general.cancel').pipe(first(), map(t => <string>t));
        return zip([title$, question$, remove$, cancel$]).pipe(
            switchMap(([ title, question, remove, cancel ]) => {
                return this.confirmationService.open({
                    title: title,
                    message: question,
                    icon: {
                        "show": true,
                        "name": "heroicons_outline:exclamation",
                        "color": "warn"
                    },
                    actions: {
                        confirm: {
                            show: true,
                            label: remove,
                            color: "warn"
                        },
                        cancel: {
                            show: true,
                            label: cancel
                        }
                    }
                }).afterClosed()
            }),
            map(res => res === 'confirmed')
        );
    }

    information(messageKey: string): Observable<boolean> {

        const title$ = this.translocoService.selectTranslate('general.info').pipe(first(), map(t => <string>t));
        const okLabel$ = this.translocoService.selectTranslate('general.ok').pipe(first(), map(t => <string>t));
        const message$ = this.translocoService.selectTranslate(messageKey).pipe(first(), map(t => <string>t));

        return zip([title$, okLabel$, message$]).pipe(
            switchMap(([title, okLabel, message]) => {
                return this.confirmationService.open({
                    title: title,
                    message: message,
                    icon: {
                        "show": true,
                        "name": "heroicons_outline:information-circle",
                        "color": "accent"
                    },
                    actions: {
                        confirm: {
                            show: true,
                            label: okLabel,
                            color: "primary"
                        },
                        cancel: {
                            show: false
                        }
                    }
                }).afterClosed()
            })
        );
    }

    confirmRemoval(options: ConfirmRemovalOptions): Observable<boolean> {
        const title$ = this.translocoService.selectTranslate('general.confirmation').pipe(first(), map(t => <string>t));
        const question$ = options.questionParams
            ? this.translocoService.selectTranslate(options.questionKey, options.questionParams).pipe(first(), map(t => <string>t))
            : this.translocoService.selectTranslate(options.questionKey).pipe(first(), map(t => <string>t));
        const remove$ = this.translocoService.selectTranslate('general.confirm').pipe(first(), map(t => <string>t));
        const cancel$ = this.translocoService.selectTranslate('general.cancel').pipe(first(), map(t => <string>t));
        return zip([title$, question$, remove$, cancel$]).pipe(
            switchMap(([ title, question, remove, cancel ]) => {
                return this.confirmationService.open({
                    title: title,
                    message: question,
                    icon: {
                        "show": true,
                        "name": "heroicons_outline:exclamation",
                        "color": "warn"
                    },
                    actions: {
                        confirm: {
                            show: true,
                            label: remove,
                            color: "warn"
                        },
                        cancel: {
                            show: true,
                            label: cancel
                        }
                    }
                }).afterClosed()
            }),
            map(res => res === 'confirmed')
        );
    }

}

