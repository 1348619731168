/* eslint-disable */
export const faqCategories = [
    {
        id   : '28924eab-97cc-465a-ba21-f232bb95843f',
        slug : 'most-asked',
        title: {
            en: 'Basic matters',
            pt: 'Assuntos básicos'
        }
    }
];

export const faqs = [

    /*************************************************************************************
     * MOST ASKED ************************************************************************
     * ***********************************************************************************
     */

    {
        id: 'f65d517a-6f69-4c88-81f5-416f47405ce1',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: {
            en: 'What is a position?',
            pt: 'O que é uma posição?'
        },
        answer: {
            en: `
                A Position is a sequence of transactions in the forex market, seeking a profit target,
                according to the trading strategy applied by the platform. 
            `,
            pt: `
                Uma posição é uma sequência de transações no mercado de câmbio, em busca de um target 
                de lucro, de acordo com a estratégia de negociação aplicada pela plataforma.
            `
        }
    },
    {
        id: '0fcece82-1691-4b98-a9b9-b63218f9deef',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: {
            en: 'Is FX8 platform operations 100% automated?',
            pt: 'As operações da plataforma FX8 são 100% automatizadas?'
        },
        answer: {
            en: `
                Yes and, due to the complexity of the algorithms, it would be impossible for a human to execute.
            `,
            pt: `
                Sim e, devido à complexidade dos algorítimos, seria impossível para um ser humano executar.
            `
        }
    }
];

export const guideCategories = [
    {
        id   : '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug : 'getting-started',
        title: {
            en: 'Getting Started',
            pt: 'Começando'
        }
    }
];
export const guides = [
    {
        id        : 'a008ffa3-7b3f-43be-8a8f-dbf5272ed2dd',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'account-opening-in-swissquote-bank',
        title     : {
            en: 'Account opening in Swissquote bank for brazilian residents',
            pt: 'Abertura de conta no banco Swissquote para residentes brasileiros'
        },
        subtitle: {
            en: 'Open your account in Swisquote bank and authorize its management by FX8 platform',
            pt: 'Abra sua conta no banco Swissquote e autorize o seu gerenciamento pela plataforma FX8'
        },
        content: {
            en: `
                <img src="assets/images/swissquote/swissquote3.jpg">

                <h2>Introduction</h2>
                
                <p>There are two methods to open the account:</p>
                
                <ul>
                    <li>Classic method</li>
                    <li>Digital method</li>
                </ul>
                
                <p>
                    The digital method requires a webcam installed in your computer or device and the appointment of a video session
                    with the bank manager, while the classic method does not and, for that reason, may be more convenient and recommendable
                    to some individuals. We will present both methods, so you may choose the one that is more preferable.  
                </p>
                
                <h2>Classic method</h2>
                
                <p>In order to open the account, follow the steps below:</p>
                
                <ol>
                    <li>
                        <p>Fill in the account opening form <a target="_blank" href="https://trade.swissquote.ch/signup/public/form/full/fx/com/individual?lang=en&partnerid=50c128c9-b744-4d1d-8b51-29303a452330#full/fx/com/individual/step1" class="link">here</a>.</p>
                        <p>Be mindful of the following observations:</p>
                        <ul>
                            <li>
                                Request the eBanking password to be sent by e-mail, because, more often than not,
                                the SMS ends up not reaching brazilian cell phones.
                             </li>
                            <li>
                                When completing the online registration, do not forget to indicate your CPF number in the "TAX ID" field.
                            </li>
                            <li>
                                You will receive a “user ID” and “password” to access the eBanking portal.
                                <em>
                                    Please, take note and keep the "user ID" and the "password" in a save place,
                                    and avoid having to contact the bank to request them again.
                                </em>
                            </li>
                        </ul>
                    </li>
                    <p></p>
                    <li>
                        Review and confirm the data. Then, print out the registration containing 8 pages.
                    </li>
                    <p></p>
                    <li>
                        Write your full name, date and sign pages 4 and 8, as well as
                        a rubric on the remaining pages.
                    </li>
                    <p></p>
                    <li>
                        Attach a certified copy (from the notary's office) of your original RG or national driving license.
                    </li>
                    <p></p>
                    <li>
                        Attach a simple copy of proof of address (water bill, electricity bill, telephone bill,
                        internet, cell phone, etc., dated less than 6 months). <em>Bank or credit card statement will not be accepted</em>.
                    </li>
                    <p></p>
                    <li>
                        In order to grant us rights to manage your account, download the <a target="_blank" href="assets/docs/limited-power-attorney.pdf" class="link">limited power of attorney</a>, 
                        print it out, fill in the fields in the client section, date and sign it and attach to the other documents.
                    </li>
                    <p></p>
                    <li>
                        Send all scanned documentation in a single file in PDF format by e-mail to <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">eduardo.gass@swissquote.ch</a>. 
                        <em>The attached file cannot exceed 9 MB.</em>
                    </li>
                    <p></p>
                    <li>
                        As soon as the scanned documentation is received by e-mail, the Compliance department will review the documentation. 
                        If it is complete, the account will be approved and a confirmation will be sent to your e-mail address, 
                        containing the bank details for the deposit. Processing time is 2 to 5 business days.
                    </li>
                    <p></p>
                    <li>
                        Subsequently, Swissquote Bank will order the DHL company to collect the original documentation at the address indicated by you.
                    </li>
                </ol>
                
                
                <h2>Digital method</h2>
                
                <p>Minimum requirements:</p>
                
                <ul>
                    <li>Brazilian passport, valid or expired for less than 1 year</li>
                    <li>Webcam</li>
                    <li>Fast Internet</li>
                    <li>
                        Computer, notebook, tablet or smartphone with Android system; Internet browser Google Chrome or Mozilla FireFox 
                    </li>
                
                </ul>
                
                <p>In order to open the account, follow the steps below:</p>
                
                <ol>
                    <li style="margin: 50px; 0;">
                        <p>Fill in the account opening form <a target="_blank" href="https://trade.swissquote.ch/signup/public/form/full/fx/com/individual?lang=en&partnerid=50c128c9-b744-4d1d-8b51-29303a452330#full/fx/com/individual/step1" class="link">here</a>.</p>
                        <p>Be mindful of the following observations:</p>
                        <ul>
                            <li>
                                Request the eBanking password to be sent by e-mail, because, more often than not,
                                it may end up not reaching brazilian cell phones.
                             </li>
                            <li>
                                When completing the online registration, do not forget to indicate your CPF number in the "TAX ID" field.
                            </li>
                            <li>
                                You will receive a “user ID” and “password” to access the eBanking portal.
                                <em>
                                    Please, take note and keep the "user ID" and the "password" in a save place,
                                    and avoid having to contact the bank to request them again.
                                </em>
                            </li>
                        </ul>
                    </li>
                    <p></p>
                    <li>
                        It will be necessary to do the identification session by webcam (in Portuguese). It is recommended
                        schedule it by sending an email to <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">eduardo.gass@swissquote.ch</a>.
                        
                        <p>
                            Due to the time difference with Switzerland, webcam authentication is available in the
                            morning shift, from 6 AM to noon (Brasília time).
                        <p>
                    </li>
                    <p></p>
                    <li>
                        After making the aforementioned video call, within the bank's website, it will be necessary to read
                        carefully and accept the terms and conditions of the account opening agreement and the risks of
                        currency market (these are 2 electronic signatures).                    
                    </li>
                    <p></p>
                    <li>
                        In order to grant us rights to manage your account, download the <a target="_blank" href="assets/docs/limited-power-attorney.pdf" class="link">limited power of attorney</a>, 
                        print it out, fill in the fields in the client section, date and sign it. Then scan and send it to 
                        <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">eduardo.gass@swissquote.ch</a>.
                    </li>
                    <p></p>
                    <li>
                        Finally, the Compliance department will review the documentation. If it is complete, the account
                        will be approved and a confirmation will be sent to your email address, containing bank details for deposit. 
                        Processing time is 1 to 3 working days.                    
                    </li>
                </ol>
                
                <h2>Conclusion</h2>
                
                <p>
                    After your account is opened and the limited power of attorney is validated, Swissquote will
                    send us directly the details of your account and it will be activated in our platform as soon as the 
                    initial deposit is verified.
                </p>
                
                <p>
                    In case of any questions about the registration process, send an e-mail to 
                    bank manager <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">Eduardo Gass</a>.
                </p>
            `,
            pt: `

                <img src="assets/images/swissquote/swissquote3.jpg">
                
                <h2>Introdução</h2>
                
                <p>Existem dois métodos para abrir a conta:</p>
                
                <ul>
                    <li>Método clássico</li>
                    <li>Método digital</li>
                </ul>
                
                <p>
                    O método digital requer uma câmera instalada em seu computador ou dispositivo e o agendamento de uma
                    vídeo conferência com o gerente do banco, enquanto que, o método clássico dispensa tal requisito e, 
                    por esta razão, pode ser mais conveniente e recomendável para alguns indivíduos. Apresentaremos ambos os métodos,
                    cabendo a você escolher o mais preferível. 
                </p>
                
                <h2>Método Clássico</h2>
                
                <p>No sentido de abrir a conta, siga os passos abaixo:</p>
                
                <ol>
                    <li>
                        <p>Preencha o formulário de abertura de conta <a target="_blank" href="https://trade.swissquote.ch/signup/public/form/full/fx/com/individual?lang=en&partnerid=50c128c9-b744-4d1d-8b51-29303a452330#full/fx/com/individual/step1">aqui</a>.</p>
                        <p>Esteja ciente das seguintes observações:</p>
                        <ul>
                            <li>
                                Solicite que a senha do eBanking seja enviada por email, pois, frequentemente,
                                o SMS acaba não chegando aos celulares brasileiros.
                             </li>
                            <li>
                                Durante o preenchimento do cadastro online, não esqueça de indicar o número do seu CPF (Cadastro de
                                Pessoa Física) no campo "TAX ID".
                            </li>
                            <li>
                                Você receberá um "User ID" e "senha" para acessar o portal eBanking.
                                <em>
                                    Por favor, tome nota e guarde-os num lugar seguro,
                                    evitando ter que contatar o banco para solicitá-los novamente.
                                </em>
                            </li>
                        </ul>
                    </li>
                    <p></p>
                    <li>
                        Revise e confirme os dados. Em seguida, imprima o cadastro em PDF contendo 8 páginas.
                    </li>
                    <p></p>
                    <li>
                        Escreva o seu nome completo, coloque a data e assine as páginas 4 e 8, assim como
                        uma rubrica nas páginas restantes.
                    </li>
                    <p></p>
                    <li>
                        Anexe uma cópia autenticada (em cartório ou tabelionato) do seu RG original ou
                        Carteira Nacional de Habilitação.
                    </li>
                    <p></p>
                    <li>
                        Anexe uma cópia simples de um comprovante de endereço (conta da água, luz, telefone,
                        internet, celular, etc., com data inferior a 6 meses). Extratos de banco ou cartão de crédito
                        não serão aceitos.
                    </li>
                    <p></p>
                    <li>
                        Para nos conceder direitos para gerenciar sua conta, baixe a <a target="_blank" href="assets/docs/limited-power-attorney.pdf" class="link">procuração limitada</a>,
                        imprima-a, preencha os campos na seção do cliente, coloque a data, assine e anexe aos outros documentos. 
                    </li>
                    <p></p>
                    <li>
                        Envie toda documentação escaneada em um único arquivo no formato PDF por e-mail a
                        <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">eduardo.gass@swissquote.ch</a>.
                        <em>O arquivo em anexo não pode exceder 9 MB.</em>
                    </li>
                    <p></p>
                    <li>
                        Uma vez recibida a documentação escaneada por e-mail, o departamento de Compliance examinará a
                        documentação. Se a mesma estiver completa, a conta será aprovada e uma confirmação será
                        enviada ao seu endereço de email, contendo os dados bancários para o depósito. O tempo de
                        tramitação é de 2 a 5 dias úteis.
                    </li>
                    <p></p>
                    <li>
                        Posteriormente, o banco Swissquote mandará a empresa DHL retirar a documentação original no
                        endereço indicado por você.
                    </li>
                </ol>
                
                
                <h2>Método digital</h2>
                
                <p>Requisitos mínimos:</p>
                
                <ul>
                    <li>Passaporte brasileiro, válido ou vencido há menos de 1 ano</li>
                    <li>Webcam</li>
                    <li>Internet rápida</li>
                    <li>
                        Computador, notebook, tablet ou smartphone com sistema Android; Navegador de Internet Google Chrome ou Mozilla FireFox 
                    </li>
                
                </ul>
                
                <p>No sentido de abrir a conta, siga os passos abaixo:</p>
                
                <ol>
                    <li>
                        <p>Preencha o formulário de abertura de conta <a target="_blank" href="https://trade.swissquote.ch/signup/public/form/full/fx/com/individual?lang=en&partnerid=50c128c9-b744-4d1d-8b51-29303a452330#full/fx/com/individual/step1" class="link">aqui</a>.</p>
                        <p>Esteja ciente das seguintes observações:</p>
                        <ul>
                            <li>
                                Solicite que a senha do eBanking seja enviada por email, pois, frequentemente,
                                o SMS acaba não chegando aos celulares brasileiros.
                             </li>
                            <li>
                                Durante o preenchimento do cadastro online, não esqueça de indicar o número do seu CPF (Cadastro de
                                Pessoa Física) no campo "TAX ID".
                            </li>
                            <li>
                                Você receberá um "User ID" e "senha" para acessar o portal eBanking.
                                <em>
                                    Por favor, tome nota e guarde-os num lugar seguro,
                                    evitando ter que contatar o banco para solicitá-los novamente.
                                </em>
                            </li>
                        </ul>
                    </li>
                    <p></p>
                    <li>
                        Será necessário fazer a sessão de identificação por webcam (em português). Recomenda-se
                        agendá-la mediante o envio de um email a <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">eduardo.gass@swissquote.ch</a>.
                        <p>
                            Em virtude do fuso horário com a Suíça, a autenticação com a webcam está disponível no
                            turno da manhã, das 6:00 até o meio-dia (horário de Brasília).
                        <p>
                    </li>
                    <p></p>
                    <li>
                        Depois de realizada a referida vídeo conferÊncia, dentro do website do banco, será necessário ler
                        atentamente e aceitar os termos e condições do contrato de abertura da conta e os riscos do
                        mercado de câmbio (tratam-se de 2 assinaturas eletrônicas).
                    </li>
                    <p></p>
                    <li>
                        Para nos conceder direitos de gerenciar sua conta, baixe a <a target="_blank" href="assets/docs/limited-power-attorney.pdf" class="link">procuração limitada</a>,
                        imprima-a, preencha os campos na seção do cliente, coloque a data e assine. Em seguida, escaneie,
                        e envie para <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">eduardo.gass@swissquote.ch</a>. 
                    </li>
                    <p></p>
                    <li>
                        Finalmente, o departamento de Compliance examinará a documentação. Se a mesma estiver completa, a conta
                        será aprovada e uma confirmação será enviada ao seu endereço de e-mail, contendo os dados bancários para
                        o depósito. O tempo de tramitação é de 1 a 3 dias úteis.
                    </li>
                </ol>
                
                <h2>Conclusão</h2>
                
                <p>
                    Após a sua conta ter sido aberta e a procuração validada, o Swissquote nos enviará diretamente os 
                    detalhes da sua conta, que será ativada em nossa plataforma assim que o depósito inicial for verificado. 
                </p>
                <p>
                    Na eventualidade de dúvidas sobre o processo de abertura de conta, envie um e-mail para 
                    o gerente <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">Eduardo Gass</a>.
                </p>
            `
        }
    },
    {
        id        : '88db2328-3178-4861-b119-5ca9a0e4fb8d',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'how-to-send-funds-swissquote',
        title     : {
            en: 'How to send funds to Swissquote for brazilian residents',
            pt: 'Como enviar fundos para o Swissquote para residentes brasileiros'
        },
        subtitle: {
            en: 'Choose the most convenient way to make the transfer to your account',
            pt: 'Escolha a forma mais conveniente de fazer a transferência para sua conta'
        },
        content: {
            en: `
                <img src="assets/images/guides/swiss-transfer.jpg">
                
                <h2>Introduction</h2>
                
                <p>The most common methods for transferring are:</p>
                
                <ul>
                    <li>Wire transfer</li>
                    <li>Regulated foreign exchange broker</li>
                </ul>
            
                <h3>Wire transfer</h3>
                
                <p>
                    Funds can be sent by bank transfer directly from bank to bank. Thus, these funds are 
                    transferred from your the bank account to your Swissquote Bank account.
                </p>
                
                <p>
                    The transfer usually takes 1-3 business days to complete. 
                </p>
                
                <p>
                    Swissquote Bank does not charge any fees to receive a transfer bank, however, 
                    the originating bank and intermediary banks will charge
                    their respective fees.                
                </p>
            
            
                <h3>Transfer by regulated exchange broker</h3>
                
                <p>
                    It is also possible to rely on duly regulated exchange brokers.
                    Here are the websites of some of these institutions:
                <p>
                
                <ul>
                
                
                    <li>
                        <a target="_blank" href="https://www.remessaonline.com.br">www.remessaonline.com.br</a>. Low costs. Easy and simple process - the preferred option for Brazilian customers.
                    </li>
                
                    <li><a target="_blank" href="https://www.wise.com">www.wise.com</a> (former Transferwise). Low costs and excellent service.
                
                    <li><a target="_blank" href="https://www.confidencecambio.com.br">www.confidencecambio.com.br</a></li>
                    <li><a target="_blank" href="https://www.rendimento.com.br">www.rendimento.com.br</a></li>
                </ul>
                
                <h2>Conclusion</h2>
                
                <p>
                    If you have any questions regarding the transfer process, please contact the Swissquote Account Manager
                    Eduardo Gass via email: <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">eduardo.gass@swissquote.ch</a>
                </p>            
            `,
            pt: `

                <img src="assets/images/guides/swiss-transfer.jpg">
                
                <h2>Introdução</h2>
                
                <p>Os métodos mais usuais para fazer a trasferência são:</p>
                
                <ul>
                    <li>Transferência bancária</li>
                    <li>Corretora de câmbio regulamentada</li>
                </ul>
            
                <h3>Transferência bancária</h3>
                
                <p>
                    Os fundos podem ser enviados por transferência bancária diretamente 
                    de banco para banco. Assim, esses recursos são transferidos da conta bancária 
                    de origem até a sua conta bancária no Swissquote Bank.
                </p>
                
                <p>
                    Normalmente, a transferência leva de 1 a 3 dias úteis para ser concluída. 
                </p>
                
                <p>
                    O Swissquote Bank não cobra nenhuma taxa para receber uma transferência 
                    bancária, porém, o banco originário e bancos intermediários cobrarão 
                    suas respectivas taxas.
                </p>
            
            
                <h3>Transferência por corretora de câmbio regulamentada</h3>
                
                <p>
                    É possível também se apoiar nas corretoras de câmbio devidamente regulamentadas. 
                    Seguem os websites de algumas dessas instituições:
                <p>
                
                <ul>
                
                
                    <li>
                        <a target="_blank" href="https://www.remessaonline.com.br">www.remessaonline.com.br</a>. Baixos custos e processo de envio fácil e 
                        simples - opção preferida dos clientes brasileiros.
                    </li>
                
                    <li><a target="_blank" href="https://www.wise.com">www.wise.com</a> (antiga Transferwise). Baixos custos e serviço de excelente qualidade.
                
                    <li><a target="_blank" href="https://www.confidencecambio.com.br">www.confidencecambio.com.br</a></li>
                    <li><a target="_blank" href="https://www.rendimento.com.br">www.rendimento.com.br</a></li>
                </ul>
                
                <h2>Conclusão</h2>
                
                <p>
                    Havendo alguma dúvida quanto ao processo de transferência, contate o gerente de contas do Swissquote
                    Eduardo Gass através do e-mail: <a target="_blank" href = "mailto: eduardo.gass@swissquote.ch">eduardo.gass@swissquote.ch</a>
                
                </p>
            
            `
        }
    }
];

// Since we only have one content for the demo, we will
// use the following mock-api on every request for every guide.
export const guideContent = `
<h2>Header Level 2</h2>

<p>
    <strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit
    amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper
    pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci,
    sagittis tempus lacus enim ac dui. <a href="#"
                                          class="link">Donec non enim</a>
    in turpis pulvinar facilisis. Ut felis.
</p>

<p>
    Orci varius natoque penatibus et magnis dis <em>parturient montes</em>, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
    himenaeos. Curabitur vitae sagittis odio. <mark>Suspendisse</mark> ullamcorper nunc non pellentesque laoreet. Curabitur eu tortor id quam pretium mattis. Proin ut quam velit.
</p>

<h3>Header Level 3</h3>

<img src="assets/images/pages/help-center/image-1.jpg">
<p class="text-secondary">
    <em>Nullam sagittis nulla in diam finibus, sed pharetra velit vestibulum. Suspendisse euismod in urna eu posuere.</em>
</p>

<h4>Header Level 4</h4>

<blockquote>
    <p>
        Blockquote. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur
        massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.
    </p>
    <footer>
        Brian Hughes
    </footer>
</blockquote>

<ol>
    <li>Ordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ol>

<h5>Header Level 5</h5>

<ul>
    <li>Unordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ul>

<pre><code>#header h1 a {
    display: block;
    width: 300px;
    height: 80px;
}</code></pre>

<h6>Header Level 6</h6>

<dl>
    <dt>Definition list</dt>
    <dd>
        Quisque sit amet risus enim. Aliquam sit amet interdum justo, at ultricies sapien. Suspendisse et semper urna, in gravida eros. Quisque id nibh iaculis, euismod urna sed,
        egestas nisi. Donec eros metus, congue a imperdiet feugiat, sagittis nec ipsum. Quisque dapibus mollis felis non tristique.
    </dd>

    <dt>Definition list</dt>
    <dd>
        Ut auctor, metus sed dapibus tempus, urna diam auctor odio, in malesuada odio risus vitae nisi. Etiam blandit ante urna, vitae placerat massa mollis in. Duis nec urna ac
        purus semper dictum ut eget justo. Aenean non sagittis augue. Sed venenatis rhoncus enim eget ornare. Donec viverra sed felis at venenatis. Mauris aliquam fringilla nulla,
        sit amet congue felis dignissim at.
    </dd>
</dl>`;
