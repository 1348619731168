import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { FuseLoadingService } from '@fuse/services/loading/loading.service';

@Injectable()
export class FuseLoadingInterceptor implements HttpInterceptor {

    handleRequestsAutomatically: boolean;

    reqs = 0;

    constructor(private _fuseLoadingService: FuseLoadingService) {
        this._fuseLoadingService.auto$
            .subscribe((value) => {
                this.handleRequestsAutomatically = value;
            });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.endsWith('deploy-code.json')) {
            return next.handle(req);
        }
        // If the Auto mode is turned off, do nothing
        if ( !this.handleRequestsAutomatically ) {
            return next.handle(req);
        }
        // Set the loading status to true
        this._fuseLoadingService._setLoadingStatus(true, 'all');
        this.reqs++;
        return next.handle(req).pipe(
            finalize(() => {
                // Set the status to false if there are any errors or the request is completed
                this.reqs--;
                const status = this.reqs > 0;
                this._fuseLoadingService._setLoadingStatus(status, 'all');
            }));
    }
}
