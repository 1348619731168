import {Injectable} from '@angular/core';
import {ApiService} from "app/shared/api.service";
import {map, Observable} from "rxjs";
import {
    KycProcess,
    KycProcessStatus, LangText,
    User,
    UserInvitationRule,
    UserLoadOptions,
    UserProfile
} from "app/shared/fx8.types";


@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private apiService: ApiService) {}

    public setKycProcessStatus(userId: string, status: KycProcessStatus, message: LangText): Observable<KycProcess> {
        return this.apiService.adminSetKycProcessStatus({ userId, status, message }).pipe(map(res => res.kycProcess));
    }

    public getKycProcess(userId: string): Observable<KycProcess> {
        return this.apiService.adminGetKycProcess({ userId, loadUserData: true }).pipe(
            map(res => res.kycProcess)
        );
    }

    public getKycProcessList(userId: string, loadUserData: boolean): Observable<KycProcess[]> {
        return this.apiService.adminGetKycProcess({ userId, loadUserData }).pipe(
            map(res => [ res.kycProcess ])
        );
    }

    public updateUserInvitationRule(userInvitationRule: UserInvitationRule): Observable<void> {
        return this.apiService.adminUpdateUserInvitationRule({ userInvitationRule }).pipe(map(_ => {}));
    }

    public addUserInvitationRule(userInvitationRule: UserInvitationRule): Observable<void> {
        return this.apiService.adminAddUserInvitationRule({ userInvitationRule }).pipe(map(_ => {}));
    }

    public getUserInvitationRule(userId: string, id: string): Observable<UserInvitationRule> {
        return this.apiService.adminGetUserInvitationRule({ userId, id }).pipe(map(res => res.userInvitationRule));
    }

    public getUserInvitationRules(userId: string): Observable<UserInvitationRule[]> {
        return this.apiService.adminGetUserInvitationRules({ userId }).pipe(map(res => res.userInvitationRule));
    }

    public deleteUserInvitationRule(userInvitationRule: UserInvitationRule): Observable<void> {
        return this.apiService.adminDeleteUserInvitationRule({ userId: userInvitationRule.userId, id: userInvitationRule.id }).pipe(map(_ => {}));
    }

    public getUserProfiles(): Observable<UserProfile[]> {
        return this.apiService.adminGetUserProfiles({}).pipe(map(res => res.userProfile));
    }

    public deleteUser(user: User): Observable<void> {
        return this.apiService.adminDeleteUser({ userId: user.id }).pipe(map(_ => {}));
    }

    public updateUser(user: User): Observable<void> {
        return this.apiService.adminUpdateUser({ user }).pipe(map(_ => {}));
    }

    public addUser(user: User, sendInvitation: boolean, temporaryPassword: string): Observable<void> {
        const messageAction = sendInvitation ? undefined : 'SUPPRESS';
        return this.apiService.adminAddUser({ user, messageAction, temporaryPassword }).pipe(map(_ => {}));
    }

    public getUsers(loadOptions: UserLoadOptions): Observable<User[]> {
        return this.apiService.adminGetUsers({ loadOptions }).pipe(map(res => res.user));
    }


    public getUser(userId: string): Observable<User> {
        return this.apiService.adminGetUser({ userId }).pipe(map(res => res.user));
    }

    public setImpersonateUser(enabled: boolean, impersonateUserId): Observable<void> {
        return this.apiService.adminSetImpersonateUser({ enabled, impersonateUserId }).pipe(map(_ => {}));
    }

    public getImpersonateUser(): Observable<{ enabled: boolean; impersonateUserId: string }> {
        return this.apiService.adminGetImpersonateUser({}).pipe(
            map(res => <{ enabled: boolean; impersonateUserId: string }>{
                enabled: res.enabled,
                impersonateUserId: res.impersonateUserId
            })
        );
    }
}
