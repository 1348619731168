import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import {CognitoAuthService} from "./cognito-auth.service";

@Injectable({
    providedIn: 'root'
})
export class CognitoNoAuthGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private authService: CognitoAuthService,
        private _router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.check();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check();
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.check();
    }

    private check(): Observable<boolean> {
        return this.authService.isSignedIn().pipe(
            switchMap(signedIn => {
                if (signedIn) {
                    this._router.navigate(['']);
                    return of(false);
                }
                else {
                    return of(true);
                }
            })
        );
    }
}
