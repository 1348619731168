import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Translation, TranslocoLoader } from '@ngneat/transloco';

import { deployCode } from 'app/../assets/general/deploy-code';


@Injectable({
    providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader {

    constructor(private _httpClient: HttpClient) {}

    getTranslation(lang: string): Observable<Translation> {
        const url = `./assets/i18n/${lang}.json?v=${deployCode}`;
        console.log('url: ' + url);
        return this._httpClient.get(url);
    }
}
