import {NgModule} from '@angular/core';
import {CognitoAuthService} from "./cognito-auth.service";
import {CognitoAuthRouterService} from "./cognito-auth-router.service";

@NgModule({
    providers: [
        CognitoAuthService,
        CognitoAuthRouterService
    ]
})
export class CognitoAuthModule {}
