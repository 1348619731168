
import {v4 as uuid} from "uuid";
import {UserInvitationRule} from "./fx8.types";

export class GeneralUtils {

    public static getInvitationLink(userInvitationRule: UserInvitationRule): string {
        return `${window.location.protocol}//${window.location.host}/sign-up?i=${userInvitationRule.code}`;
    }

    public static generateId(): string {
        return uuid();
    }

    public static log(object: any) {
        console.log(JSON.stringify(object, null, 2));4431
    }

    public static replaceAll(str: string, find: string, replace: string) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }

    private static escapeRegExp(s: string) {
        return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
}
