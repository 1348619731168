import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, timer} from 'rxjs';
import {CognitoAuthService} from "../../../core/cognito-auth/cognito-auth.service";

@Injectable({
  providedIn: "root"
})
export class SignOutResolver implements Resolve<any> {

  constructor(private authService: CognitoAuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.authService.signOut();
  }
}

