import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {of, take, zip} from 'rxjs';
import {AvailableLangs, TranslocoService} from '@ngneat/transloco';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {CognitoAuthService} from "../../../core/cognito-auth/cognito-auth.service";
import {TranslocoLocaleService} from "@ngneat/transloco-locale";


@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    encapsulation  : ViewEncapsulation.None,
})
export class LanguagesComponent implements OnInit, OnDestroy {

    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    constructor(
        private authService: CognitoAuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private fuseNavigationService: FuseNavigationService,
        private translocoService: TranslocoService,
        private translocoLocaleService: TranslocoLocaleService
    ) {}

    ngOnInit(): void {
        this.availableLangs = this.translocoService.getAvailableLangs();
        this.translocoService.langChanges$.subscribe((activeLang) => {
            this.activeLang = activeLang;
            this._updateNavigation(activeLang);

            if (activeLang == 'pt') {
                this.translocoLocaleService.setLocale('pt-BR');
            }
            else {
                this.translocoLocaleService.setLocale('en-US');
            }
        });
        this.flagCodes = {
            'en': 'us',
            'pt': 'br'
        };
        this.authService.getUser().subscribe(user => {
            const lang = user.language ? user.language : 'en';
            if (lang !== this.translocoService.getActiveLang()) {
                this.translocoService.setActiveLang(lang);
            }
        });
    }

    ngOnDestroy(): void {}

    setActiveLang(lang: string): void {
        if (this.translocoService.getActiveLang() !== lang) {

            this.authService.updateUserAttributes({
                attributes: { 'custom:lang': lang },
                onSuccess: () => {
                    this.translocoService.setActiveLang(lang);
                },
                onError: message => {
                    console.log(message);
                }
            });
        }
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private _updateNavigation(lang: string): void {
        const navComponent = this.fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        if (!navComponent) {
            return null;
        }
        this.translateItemTitles(navComponent, "main", "side-menu.main.title", "side-menu.main.subtitle");
        this.translateItemTitles(navComponent, "main.home", "side-menu.main.home");

        this.translateItemTitles(navComponent, "main.pool-performance", "side-menu.main.performance");
        this.translateItemTitles(navComponent, "main.performance", "side-menu.main.performance");

        this.translateItemTitles(navComponent, "main.pool-account-report", "side-menu.main.pool-account-report");
        this.translateItemTitles(navComponent, "main.pool-deposit", "side-menu.main.pool-deposit");
        this.translateItemTitles(navComponent, "main.pool-withdrawal", "side-menu.main.pool-withdrawal");
        this.translateItemTitles(navComponent, "main.bank-account", "side-menu.main.bank-account");


        this.translateItemTitles(navComponent, "main.simulator", "side-menu.main.simulator");
        this.translateItemTitles(navComponent, "main.settings", "side-menu.main.settings");
        this.translateItemTitles(navComponent, "main.help-center", "side-menu.main.help-center.title");
        this.translateItemTitles(navComponent, "main.help-center.home", "side-menu.main.help-center.home");
        this.translateItemTitles(navComponent, "main.help-center.faqs", "side-menu.main.help-center.faqs");
        this.translateItemTitles(navComponent, "main.help-center.guides", "side-menu.main.help-center.guides");
        this.translateItemTitles(navComponent, "main.help-center.support", "side-menu.main.help-center.support");

        this.translateItemTitles(navComponent, "internal", "side-menu.internal.title", "side-menu.internal.subtitle");
        this.translateItemTitles(navComponent, "internal.pool", "side-menu.internal.pool");
        this.translateItemTitles(navComponent, "internal.simulator", "side-menu.internal.simulator");

        this.translateItemTitles(navComponent, "backoffice", "side-menu.backoffice.title", "side-menu.backoffice.subtitle");
        this.translateItemTitles(navComponent, "backoffice.pool", "side-menu.backoffice.pool");

        this.translateItemTitles(navComponent, "private", "side-menu.private.title", "side-menu.private.subtitle");
        this.translateItemTitles(navComponent, "private.simulator", "side-menu.private.simulator");
        this.translateItemTitles(navComponent, "private.account-report", "side-menu.private.account-report");
        this.translateItemTitles(navComponent, "private.account-performance", "side-menu.private.account-performance");

        this.translateItemTitles(navComponent, "admin", "side-menu.admin.title", "side-menu.admin.subtitle");
        this.translateItemTitles(navComponent, "admin.user-profile", "side-menu.admin.user-profile");
        this.translateItemTitles(navComponent, "admin.user", "side-menu.admin.user");
        this.translateItemTitles(navComponent, "admin.fx8-account", "side-menu.admin.fx8-account");

        this.translateItemTitles(navComponent, "admin.config", "side-menu.admin.config");
        this.translateItemTitles(navComponent, "admin.contract-model", "side-menu.admin.contract-model");
        this.translateItemTitles(navComponent, "admin.agreement", "side-menu.admin.agreement");
    }

    private translateItemTitles(navComponent: FuseVerticalNavigationComponent, itemId: string, titleKey: string, subtitleKey: string = null) {
        const navigation = navComponent.navigation;
        const item = this.fuseNavigationService.getItem(itemId, navigation);
        if (item) {
            const titleTrans = this.translocoService.selectTranslate(titleKey).pipe(take(1));
            let subtitleTrans = of(null);
            if (subtitleKey !== null) {
                subtitleTrans = this.translocoService.selectTranslate(subtitleKey).pipe(take(1));
            }
            zip([titleTrans, subtitleTrans]).subscribe(([title, subtitle]) => {
                item.title = title;
                if (subtitle != null) item.subtitle = subtitle;
                navComponent.refresh();
            });
        }
    }
}
