import {Route} from '@angular/router';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';
import {CognitoAuthGuard} from "./core/cognito-auth/cognito-auth.guard";
import {SignOutResolver} from "./modules/auth/sign-out/sign-out.resolver";
import {CognitoNoAuthGuard} from "./core/cognito-auth/cognito-no-auth.guard";
import {LanguageResolver} from "./shared/language-resolver.resolver";
import {InternalRoutingGuard} from "./core/cognito-auth/internal-routing-guard.service";
import {InvitationGuard} from "./core/cognito-auth/invitation.guard";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    { path: '', pathMatch : 'full', redirectTo: 'main/home' },


    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.

    { path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'main/home' },

    {
        path: '',
        canActivate: [ InvitationGuard, CognitoNoAuthGuard ],
        canActivateChild: [ CognitoNoAuthGuard ],
        component: LayoutComponent,
        resolve: {
            language: LanguageResolver
        },
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirm-sign-up',
                canActivate: [ InternalRoutingGuard ],
                canActivateChild: [ InternalRoutingGuard ],
                loadChildren: () => import('app/modules/auth/confirm-sign-up/confirm-sign-up.module').then(m => m.ConfirmSignUpModule),
            },
            {
                path: 'forgot-password-submit',
                canActivate: [ InternalRoutingGuard ],
                canActivateChild: [ InternalRoutingGuard ],
                loadChildren: () => import('app/modules/auth/forgot-password-submit/forgot-password-submit.module').then(m => m.ForgotPasswordSubmitModule)
            },
            {
                path: 'sign-up-complete',
                canActivate: [ InternalRoutingGuard ],
                canActivateChild: [ InternalRoutingGuard ],
                loadChildren: () => import('app/modules/auth/sign-up-complete/sign-up-complete.module').then(m => m.SignUpCompleteModule)
            },
            {
                path: 'password-reset-confirmation',
                canActivate: [ InternalRoutingGuard ],
                canActivateChild: [ InternalRoutingGuard ],
                loadChildren: () => import('app/modules/auth/password-reset-confirmation/password-reset-confirmation.module').then(m => m.PasswordResetConfirmationModule)
            },
            {
                path: 'new-password-required',
                canActivate: [ InternalRoutingGuard ],
                canActivateChild: [ InternalRoutingGuard ],
                loadChildren: () => import('app/modules/auth/new-password-required/new-password-required.module').then(m => m.NewPasswordRequiredModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            }
        ]
    },

    {
        path: '',
        canActivate: [CognitoAuthGuard],
        canActivateChild: [CognitoAuthGuard],
        resolve: {
            signOutResolver: SignOutResolver
        },
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
            }
        ]
    },

    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {
                path: 'home',
                loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)
            },
        ]
    },

    {
        path       : '',
        canActivate: [CognitoAuthGuard],
        canActivateChild: [CognitoAuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {
                path       : '',
                loadChildren: () => import('app/modules/admin/common/main/main.module').then(m => m.MainModule)
            }
        ]
    },

    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {
                path: 'pages/error/404',
                loadChildren: () => import('app/modules/pages/error/error-404/error-404.module').then(m => m.Error404Module)
            },
        ]
    },

    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {
                path: 'pages/contract',
                loadChildren: () => import('app/modules/pages/contract/contract.module').then(m => m.ContractModule)
            },
        ]
    }
];
