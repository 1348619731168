import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FlexLayoutModule} from "@angular/flex-layout";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {Fx8ApiInterceptor} from "./fx8-api.interceptor";
import {TranslocoCoreModule} from "../core/transloco/transloco.module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslocoCoreModule

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslocoCoreModule
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: Fx8ApiInterceptor,
        multi: true
    }]
})
export class SharedModule
{
}
