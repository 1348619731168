import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {LanguageService} from "./language.service";
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
    providedIn: 'root'
})
export class LanguageResolver implements Resolve<string> {

    constructor(
        private languageService: LanguageService,
        private translocoService: TranslocoService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
        const lang = this.languageService.getBrowserLanguage();
        this.translocoService.setActiveLang(lang);
        return lang;
    }
}
