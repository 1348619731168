import {
    AfterViewInit, ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild, ViewEncapsulation
} from '@angular/core';
import {timeout} from "rxjs";

interface LogoUrl {
    time: number;
    dark: string;
    light: string;
}

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class LogoComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('logoImage') logoImage: ElementRef;

    @Input() name: string = 'dragon-knight';
    @Input() type: string = 'light';
    @Input() imageClass: string = ''


    image = new Image();
    timeout = null;

    constructor(private cdref: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.createImage();
    }

    ngAfterViewInit() {
        this.startTimeout(0);
    }

    ngOnDestroy() {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
    }

    startTimeout(timeout: number) {
        this.timeout = setTimeout(() => {
            this.logoImage.nativeElement.src = this.image.src;
            this.cdref.detectChanges();
            this.startTimeout(20_000);
        }, timeout);
    }

    createImage() {
        this.image.src = this.type === 'light'
            ? `assets/images/logo/${this.name}/${this.name}-light.gif`
            : `assets/images/logo/${this.name}/${this.name}.gif`;
    }

}
