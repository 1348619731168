import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {combineLatest, first, map, Observable, ReplaySubject, switchMap, tap} from 'rxjs';
import {Navigation} from 'app/core/navigation/navigation.types';
import {UserService} from "../user/user.service";
import {User, UserProfileConfig} from "../../shared/fx8.types";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    constructor(
        private _httpClient: HttpClient,
        private userService: UserService
    ) {}

    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    get(): Observable<Navigation> {
        const userProfileConfig$ = this.userService.userProfileConfig$.pipe(first());
        const user$ = this.userService.user$.pipe(first());
        return combineLatest([user$, userProfileConfig$]).pipe(
            switchMap(([user, userProfile]) => {
                return this._httpClient.get<Navigation>('api/common/navigation').pipe(
                    map(navigation => this.applyProfile(user, userProfile, navigation)),
                    tap((navigation) => {
                        this._navigation.next(navigation);
                    })
                );
            })
        );
    }

    private applyProfile(user: User, userProfile: UserProfileConfig, navigation: Navigation): Navigation {
        navigation = this.applyProfileOnAdminMenu(user, navigation);
        navigation = this.applyProfileOnMainMenu(user, userProfile, navigation);
        navigation = this.applyProfileOnPrivateMenu(user, userProfile, navigation);
        navigation = this.applyProfileOnInternalMenu(user, userProfile, navigation);
        navigation = this.applyProfileOnBackofficeMenu(user, userProfile, navigation);
        return navigation;
    }

    private applyProfileOnBackofficeMenu(user: User, userProfile: UserProfileConfig, navigation: Navigation): Navigation {

        const index = navigation.default.findIndex(i => i.id === 'backoffice');
        let backofficeItem = navigation.default[index];
        backofficeItem.hidden = _ => true;

        const backofficeMenuProfile = userProfile?.viewProfile?.sideMenuProfile?.backofficeMenuProfile;

        if (backofficeMenuProfile) {
            backofficeItem.hidden = _ => !backofficeMenuProfile.visible;
            const poolItem = backofficeItem.children.find(c => c.id === 'backoffice.pool');
            poolItem.hidden = _ => !backofficeMenuProfile.poolItemVisible;
            navigation.default[index] = backofficeItem;
        }

        return navigation;
    }

    private applyProfileOnInternalMenu(user: User, userProfile: UserProfileConfig, navigation: Navigation): Navigation {

        const index = navigation.default.findIndex(i => i.id === 'internal');
        let internalItem = navigation.default[index];
        internalItem.hidden = _ => true;

        const internalMenuProfile = userProfile?.viewProfile?.sideMenuProfile?.internalMenuProfile;

        if (internalMenuProfile) {
            internalItem.hidden = _ => !internalMenuProfile.visible;
            const simulatorItem = internalItem.children.find(c => c.id === 'internal.simulator');
            const poolItem = internalItem.children.find(c => c.id === 'internal.pool');
            simulatorItem.hidden = _ => !internalMenuProfile.simulatorItemVisible;
            poolItem.hidden = _ => !internalMenuProfile.poolItemVisible;
            navigation.default[index] = internalItem;
        }

        return navigation;
    }


    private applyProfileOnPrivateMenu(user: User, userProfile: UserProfileConfig, navigation: Navigation): Navigation {
        
        const index = navigation.default.findIndex(i => i.id === 'private');
        let privateItem = navigation.default[index];
        privateItem.hidden = _ => true;

        const privateMenuProfile = userProfile?.viewProfile?.sideMenuProfile?.privateMenuProfile;

        if (privateMenuProfile) {
            privateItem.hidden = _ => !privateMenuProfile.visible;
            const simulatorItem = privateItem.children.find(c => c.id === 'private.simulator');
            const accountItem = privateItem.children.find(c => c.id === 'private.account-report');
            const accountPerformanceItem = privateItem.children.find(c => c.id === 'private.account-performance');

            simulatorItem.hidden = _ => !privateMenuProfile.simulatorItemVisible;
            accountItem.hidden = _ => !privateMenuProfile.accountItemVisible;
            accountPerformanceItem.hidden = _ => !privateMenuProfile.accountPerformanceItemVisible;
            navigation.default[index] = privateItem;
        }

        return navigation;
    }

    private applyProfileOnMainMenu(user: User, userProfile: UserProfileConfig, navigation: Navigation): Navigation {

        const index = navigation.default.findIndex(i => i.id === 'main');
        let mainItem = navigation.default[index];
        mainItem.hidden = _ => true;

        const mainMenuProfile = userProfile?.viewProfile?.sideMenuProfile?.mainMenuProfile;
        if (mainMenuProfile) {

            mainItem.hidden = _ => !mainMenuProfile.visible;

            const homeItem = mainItem.children.find(c => c.id === 'main.home');
            const poolPerformanceItem = mainItem.children.find(c => c.id === 'main.pool-performance');
            const poolAccountItem = mainItem.children.find(c => c.id === 'main.pool-account-report');

            const poolDepositItem = mainItem.children.find(c => c.id === 'main.pool-deposit');
            const poolWithdrawalItem = mainItem.children.find(c => c.id === 'main.pool-withdrawal');
            const bankAccountItem = mainItem.children.find(c => c.id === 'main.bank-account');

            const simulatorItem = mainItem.children.find(c => c.id === 'main.simulator');
            const settingsItem = mainItem.children.find(c => c.id === 'main.settings');
            const helpItem = mainItem.children.find(c => c.id === 'main.help-center');

            homeItem.hidden = _ => !mainMenuProfile.homeItemVisible;
            poolPerformanceItem.hidden = _ => !mainMenuProfile.poolPerformanceVisisble;
            poolAccountItem.hidden = _ => !mainMenuProfile.poolAccountItemVisible;
            poolDepositItem.hidden = _ => !mainMenuProfile.poolDepositItemVisible;
            poolWithdrawalItem.hidden = _ => !mainMenuProfile.poolWithdrawalItemVisible;
            bankAccountItem.hidden = _ => !mainMenuProfile.bankAccountItemVisible;
            simulatorItem.hidden = _ => !mainMenuProfile.simulatorItemVisible;
            settingsItem.hidden = _ => !mainMenuProfile.settingsItemVisible;
            helpItem.hidden = _ => !mainMenuProfile.helpItemVisible;

            if (mainMenuProfile.helpItemVisible) {
                const helpMenuProfile = mainMenuProfile.helpMenuProfile;
                const helpHomeItem = helpItem.children.find(c => c.id === 'main.help-center.home');
                const helpFaqItem = helpItem.children.find(c => c.id === 'main.help-center.faqs');
                const helpGuideItem = helpItem.children.find(c => c.id === 'main.help-center.guides');
                const helpSupportItem = helpItem.children.find(c => c.id === 'main.help-center.support');
                helpHomeItem.hidden = _ => !helpMenuProfile.homeItemVisible;
                helpFaqItem.hidden = _ => !helpMenuProfile.faqItemVisible;
                helpGuideItem.hidden = _ => !helpMenuProfile.guideItemVisible;
                helpSupportItem.hidden = _ => !helpMenuProfile.supportItemVisible;
            }
            navigation.default[index] = mainItem;
            if (!user.admin) {
                navigation.default = navigation.default.filter(i => i.id !== 'admin');
            }
            if (user.admin && user.impersonateUserId !== undefined) {
                navigation.default = navigation.default.filter(i => i.id !== 'admin');
            }
        }

        return navigation;
    }

    private applyProfileOnAdminMenu(user: User, navigation: Navigation): Navigation {
        if (!user.admin) {
            navigation.default = navigation.default.filter(i => i.id !== 'admin');
        }
        if (user.admin && user.impersonateUserId !== undefined) {
            navigation.default = navigation.default.filter(i => i.id !== 'admin');
        }
        return navigation;
    }
}
