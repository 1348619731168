import {Amplify, Auth, Hub} from 'aws-amplify';
import {environment} from 'environments/environment';
import {Injectable} from "@angular/core";
import {
    BehaviorSubject,
    catchError,
    concat,
    defer,
    filter,
    finalize,
    first,
    from,
    map,
    Observable,
    of,
    switchMap
} from 'rxjs';
import {CognitoUser} from "amazon-cognito-identity-js";
import {FuseLoadingService} from "../../../@fuse/services/loading";
import {UserService} from "../user/user.service";
import {User} from "../../shared/fx8.types";

Amplify.configure({
    Auth: environment.cognito
});



export enum SignInResult {
    SUCCESS = 'SUCCESS',
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
}

export interface SignInParams {
    email: string;
    password: string;
    onSuccess: (admin: boolean) => void
    onNewPasswordRequired: () => void
    onUserNotConfirmed: () => void
    onError: (message: string) => void
}

export interface CompleteNewPasswordParams {
    newPassword: string,
    onSuccess: () => void,
    onError: (message: string) => void
}

export interface ForgotPasswordParams {
    email: string,
    onSuccess: () => void,
    onError: (message: string) => void
}

export interface ForgotPasswordSubmitParams {
    email: string,
    code: string,
    password: string,
    onSuccess: () => void,
    onError: (message: string) => void
}

export interface SignUpParams {
    email: string,
    name: string,
    accountType: string,
    firstName: string,
    lastName: string,
    companyName: string,
    invitationCode: string;
    password: string,
    language: string,
    onSuccess: () => void,
    onError: (message: string) => void
}

export interface ConfirmSignUpParams {
    email: string,
    code: string,
    onSuccess: () => void,
    onError: (message: string) => void
}

export interface ResendSignUpParams {
    email: string,
    onSuccess: () => void,
    onError: (message: string) => void
}

export interface ChangePasswordParams {
    currentPassword: string,
    newPassword: string,
    onSuccess: () => void,
    onError: (message: string) => void
}

export interface UpdateUserAttributesParams {
    attributes: object,
    onSuccess: () => void,
    onError: (message: string) => void
}

@Injectable()
export class CognitoAuthService {

    cognitoUser: BehaviorSubject<CognitoUser> = new BehaviorSubject(null);

    constructor(
        private fuseLoadingService: FuseLoadingService,
        private userService: UserService
    ) {
        this.setUpListener();
    }

    private setUpListener() {
        this.getUser().subscribe(user => this.userService.user = user)
        const listener = (data) => {
            // console.log(JSON.stringify(data));
            switch (data.payload.event) {
                case 'signIn':
                    console.log('user signed in');
                    this.getUser().subscribe(user => this.userService.user = user);
                    break;
                case 'tokenRefresh':
                    console.log('token refresh succeeded');
                    this.getUser().subscribe(user => this.userService.user = user);
                    break;
            };
        }
        Hub.listen('auth', listener);
    }

    public getAccessToken(): Observable<string> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            map(res => {
                const cognitoUser = <CognitoUser>res;
                return cognitoUser.getSignInUserSession().getAccessToken().getJwtToken();
            }),
            filter(u => !!u)
        );
    }


    public getUser(): Observable<User> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            map(res => {
                const cognitoUser = <CognitoUser>res;
                const groups = cognitoUser.getSignInUserSession().getIdToken().payload['cognito:groups'];
                const admin = groups && groups.length > 0 && groups[0] === 'admin';
                const user: User = {
                    id: cognitoUser.getUsername(),
                    admin: admin,
                    email: cognitoUser.getSignInUserSession().getIdToken().payload['email'],
                    name: cognitoUser.getSignInUserSession().getIdToken().payload['name'],


                    language: cognitoUser.getSignInUserSession().getIdToken().payload['custom:lang'],
                    profile: cognitoUser.getSignInUserSession().getIdToken().payload['custom:profile'],
                    firstName: cognitoUser.getSignInUserSession().getIdToken().payload['custom:firstName'],
                    lastName: cognitoUser.getSignInUserSession().getIdToken().payload['custom:lastName'],
                    companyName: cognitoUser.getSignInUserSession().getIdToken().payload['custom:companyName'],
                    accountType: cognitoUser.getSignInUserSession().getIdToken().payload['custom:accountType'],


                    impersonateUserId: cognitoUser.getSignInUserSession().getIdToken().payload['custom:impersonateUserId'],
                    idToken: cognitoUser.getSignInUserSession().getIdToken().getJwtToken(),
                    accessToken: cognitoUser.getSignInUserSession().getAccessToken().getJwtToken(),
                    supervisee: [],
                    fx8Account: []
                }
                return user;
            }),
            // tap(user => this.userService.user = user),
            filter(u => !!u)
        );
    }

    public isSignedIn(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            catchError(_ => {
                return of(false);
            }),
            map(res => {
                return !(res === false);
            })
        );
    }

    public signOut(): Observable<any> {
        return defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'signOut');
            return from(Auth.signOut()).pipe(
                map(res => <CognitoUser>res),
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'signOut'))
            );
        });
    }

    public confirmSignUp(params: ConfirmSignUpParams) {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'signIn');
            return from(Auth.confirmSignUp(params.email, params.code)).pipe(
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'signIn'))
            );
        }).subscribe({
            next: res => params.onSuccess(),
            error: error => params.onError(error.message)
        })
    }

    public updateUserAttributes(params: UpdateUserAttributesParams) {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'updateUserAttributes');
            return from(Auth.currentAuthenticatedUser()).pipe(
                first(),
                switchMap(user => from(Auth.updateUserAttributes(user, params.attributes))),
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'updateUserAttributes'))
            );
        }).subscribe({
            next: res => params.onSuccess(),
            error: error => params.onError(error.message)
        })
    }

    public changePassword(params: ChangePasswordParams) {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'changePassword');
            return from(Auth.currentAuthenticatedUser()).pipe(
                first(),
                switchMap(user => from(Auth.changePassword(user, params.currentPassword, params.newPassword))),
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'changePassword'))
            );
        }).subscribe({
            next: res => params.onSuccess(),
            error: error => params.onError(error.message)
        })
    }

    public signUp(process$: Observable<void>, params: SignUpParams) {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'signIn');
            return process$.pipe(
                switchMap(_ => {
                    return from(Auth.signUp({
                        username: params.email,
                        password: params.password,
                        attributes: {
                            name: params.name,
                            email: params.email,
                            'custom:lang': params.language,
                            'custom:accountType': params.accountType,
                            'custom:firstName': params.firstName,
                            'custom:lastName': params.lastName,
                            'custom:companyName': params.companyName,
                            'custom:invitationCode': params.invitationCode,
                        }
                    }));
                }),
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'signIn'))
            );
        }).subscribe({
            next: res => params.onSuccess(),
            error: error => params.onError(error.message)
        })
    }

    public signIn(params: SignInParams) {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'signIn');
            return from(Auth.signIn(params.email, params.password)).pipe(
                map(res => <CognitoUser>res),
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'signIn'))
            );
        }).subscribe({
            next: user => {
                this.cognitoUser.next(user);
                if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
                    params.onNewPasswordRequired();
                }
                else {
                    const groups = user.getSignInUserSession().getIdToken().payload['cognito:groups'];
                    const admin = groups && groups.length > 0 && groups[0] === 'admin';
                    params.onSuccess(admin);
                }
            },
            error: error => {
                if (error['name'] == 'UserNotConfirmedException') {
                    params.onUserNotConfirmed();
                }
                else {
                    params.onError(error.message);
                }
            }
        })
    }

    public completeNewPassword(params: CompleteNewPasswordParams): void {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'completeNewPassword');
            return this.cognitoUser.asObservable().pipe(
                filter(user => user !== null),
                first(),
                switchMap(user => from(Auth.completeNewPassword(user, params.newPassword))),
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'completeNewPassword'))
            );
        }).subscribe({
            next: _ => params.onSuccess(),
            error: error => params.onError(error.message)
        });
    }

    public forgotPassword(params: ForgotPasswordParams): void {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'forgotPassword');
            return from(Auth.forgotPassword((params.email))).pipe(
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'forgotPassword'))
            );
        }).subscribe({
            next: _ => params.onSuccess(),
            error: error => params.onError(error.message)
        });
    }

    public forgotPasswordSubmit(params: ForgotPasswordSubmitParams): void {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'forgotPasswordSubmit');
            return from(Auth.forgotPasswordSubmit(params.email, params.code, params.password)).pipe(
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'forgotPasswordSubmit'))
            );
        }).subscribe({
            next: _ => params.onSuccess(),
            error: error => params.onError(error.message)
        });
    }

    public resendSignUp(params: ResendSignUpParams): void {
        defer(() => {
            this.fuseLoadingService._setLoadingStatus(true, 'resendSignUp');
            return from(Auth.resendSignUp(params.email)).pipe(
                finalize(() => this.fuseLoadingService._setLoadingStatus(false, 'resendSignUp'))
            );
        }).subscribe({
            next: _ => params.onSuccess(),
            error: error => params.onError(error.message)
        });

    }


}


