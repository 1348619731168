import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";

import {environment} from 'environments/environment';
import {
    AddBankAccountRequest,
    AddBankAccountResponse,
    AddPoolWithdrawalRequest,
    AddPoolWithdrawalResponse,
    AdminAddContractModelRequest,
    AdminAddContractModelResponse,
    AdminAddFx8AccountRequest,
    AdminAddUserInvitationRuleRequest,
    AdminAddUserProfileRequest,
    AdminAddUserRequest,
    AdminCreateConfigRequest,
    AdminCreateConfigResponse,
    AdminDeleteAgreementRequest,
    AdminDeleteAgreementResponse,
    AdminDeleteConfigRequest,
    AdminDeleteConfigResponse,
    AdminDeleteContractModelRequest,
    AdminDeleteContractModelResponse,
    AdminDeleteFx8AccountRequest,
    AdminDeleteFx8AccountResponse,
    AdminDeleteUserInvitationRuleRequest,
    AdminDeleteUserInvitationRuleResponse,
    AdminDeleteUserProfileRequest,
    AdminDeleteUserProfileResponse,
    AdminDeleteUserRequest,
    AdminDeleteUserResponse,
    AdminGetAgreementListRequest,
    AdminGetAgreementListResponse, AdminGetAgreementPdfRequest, AdminGetAgreementPdfResponse,
    AdminGetAgreementRequest,
    AdminGetAgreementResponse,
    AdminGetAppConfigSchemaRequest,
    AdminGetAppConfigSchemaResponse,
    AdminGetConfigRequest,
    AdminGetConfigResponse,
    AdminGetConfigsRequest,
    AdminGetConfigsResponse,
    AdminGetContractModelListRequest,
    AdminGetContractModelListResponse,
    AdminGetContractModelRequest,
    AdminGetContractModelResponse,
    AdminGetFieldDataSourceSetRequest,
    AdminGetFieldDataSourceSetResponse,
    AdminGetFx8AccountRequest,
    AdminGetFx8AccountResponse,
    AdminGetFx8AccountsRequest,
    AdminGetFx8AccountsResponse,
    AdminGetImpersonateUserRequest,
    AdminGetImpersonateUserResponse,
    AdminGetKycProcessRequest,
    AdminGetPoolsRequest,
    AdminGetPoolsResponse,
    AdminGetTargetAccountsRequest,
    AdminGetTargetAccountsResponse,
    AdminGetUserInvitationRuleRequest,
    AdminGetUserInvitationRuleResponse,
    AdminGetUserInvitationRulesRequest,
    AdminGetUserInvitationRulesResponse,
    AdminGetUserProfileRequest,
    AdminGetUserProfileResponse,
    AdminGetUserProfilesRequest,
    AdminGetUserProfilesResponse,
    AdminGetUserRequest,
    AdminGetUserResponse,
    AdminGetUsersRequest,
    AdminGetUsersResponse,
    AdminSetImpersonateUserRequest,
    AdminSetImpersonateUserResponse,
    AdminSetKycProcessStatusRequest,
    AdminSetKycProcessStatusResponse,
    AdminUpdateConfigRequest,
    AdminUpdateConfigResponse,
    AdminUpdateFx8AccountRequest,
    AdminUpdateFx8AccountResponse,
    AdminUpdateUserInvitationRuleRequest,
    AdminUpdateUserInvitationRuleResponse,
    AdminUpdateUserProfileRequest,
    AdminUpdateUserProfileResponse,
    AdminUpdateUserRequest,
    AdminUpdateUserResponse,
    ApiRequest,
    ApiResponse,
    CancelPoolWithdrawalRequest,
    CancelPoolWithdrawalResponse,
    EchoRequest,
    EchoResponse,
    GetAccountPerformanceReportSummariesRequest,
    GetAccountPerformanceReportSummariesResponse,
    GetAccountPerformanceReportSummaryRequest,
    GetAccountPerformanceReportSummaryResponse,
    GetAccountReportSummariesRequest,
    GetAccountReportSummariesResponse,
    GetAccountReportSummaryRequest,
    GetAccountReportSummaryResponse, GetAgreementPdfRequest, GetAgreementPdfResponse,
    GetBankAccountRequest,
    GetBankAccountResponse,
    GetBankAccountsRequest,
    GetBankAccountsResponse,
    GetHelpCenterConfigReportRequest,
    GetHelpCenterConfigReportResponse,
    GetHelpCenterViewProfileConfigRequest,
    GetHelpCenterViewProfileConfigResponse,
    GetKycAgreementDataRequest,
    GetKycAgreementDataResponse,
    GetKycProcessRequest,
    GetKycProcessResponse, GetLastAgreementsRequest, GetLastAgreementsResponse,
    GetPerformancePoolReportRequest,
    GetPerformancePoolReportResponse,
    GetPoolReportRequest,
    GetPoolReportResponse,
    GetSimulationAccountRequest,
    GetSimulationAccountResponse,
    GetStrategyInfoListRequest,
    GetStrategyInfoListResponse,
    GetUserInvitationRulesRequest,
    GetUserInvitationRulesResponse,
    GetUserProfileConfigRequest,
    GetUserProfileConfigResponse,
    GetUserProfileRequest,
    GetUserProfileResponse,
    HelloRequest,
    HelloResponse,
    RemoveBankAccountRequest,
    RemoveBankAccountResponse,
    SubmitKycDataRequest,
    SubmitKycDataResponse
} from 'app/shared/fx8.types';
import {ApiSupportService} from "./api-support.service";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private apiSupportService: ApiSupportService) {}

    public adminGetAgreementList(request: AdminGetAgreementListRequest): Observable<AdminGetAgreementListResponse> {
        return this.post({ adminGetAgreementListRequest: request }).pipe(map(res => res.adminGetAgreementListResponse));
    }

    public adminDeleteAgreement(request: AdminDeleteAgreementRequest): Observable<AdminDeleteAgreementResponse> {
        return this.post({ adminDeleteAgreementRequest: request }).pipe(map(res => res.adminDeleteAgreementResponse));
    }

    public adminGetAgreementPdf(request: AdminGetAgreementPdfRequest): Observable<AdminGetAgreementPdfResponse> {
        return this.post({ adminGetAgreementPdfRequest: request }).pipe(map(res => res.adminGetAgreementPdfResponse));
    }

    public adminGetAgreement(request: AdminGetAgreementRequest): Observable<AdminGetAgreementResponse> {
        return this.post({ adminGetAgreementRequest: request }).pipe(map(res => res.adminGetAgreementResponse));
    }

    public adminGetContractModelList(request: AdminGetContractModelListRequest): Observable<AdminGetContractModelListResponse> {
        return this.post({ adminGetContractModelListRequest: request }).pipe(map(res => res.adminGetContractModelListResponse));
    }

    public adminDeleteContractModel(request: AdminDeleteContractModelRequest): Observable<AdminDeleteContractModelResponse> {
        return this.post({ adminDeleteContractModelRequest: request }).pipe(map(res => res.adminDeleteContractModelResponse));
    }

    public adminGetContractModel(request: AdminGetContractModelRequest): Observable<AdminGetContractModelResponse> {
        return this.post({ adminGetContractModelRequest: request }).pipe(map(res => res.adminGetContractModelResponse));
    }

    public adminAddContractModel(request: AdminAddContractModelRequest): Observable<AdminAddContractModelResponse> {
        return this.post({ adminAddContractModelRequest: request }).pipe(map(res => res.adminAddContractModelResponse));
    }

    public adminSetKycProcessStatus(request: AdminSetKycProcessStatusRequest): Observable<AdminSetKycProcessStatusResponse> {
        return this.post({ adminSetKycProcessStatusRequest: request }).pipe(map(res => res.adminSetKycProcessStatusResponse));
    }

    public adminGetKycProcess(request: AdminGetKycProcessRequest): Observable<GetKycProcessResponse> {
        return this.post({ adminGetKycProcessRequest: request }).pipe(map(res => res.adminGetKycProcessResponse));
    }

    public getKycAgreementData(request: GetKycAgreementDataRequest): Observable<GetKycAgreementDataResponse> {
        return this.post({ getKycAgreementDataRequest: request }).pipe(map(res => res.getKycAgreementDataResponse));
    }


    public submitKycData(request: SubmitKycDataRequest): Observable<SubmitKycDataResponse> {
        return this.post({ submitKycDataRequest: request }).pipe(map(res => res.submitKycDataResponse));
    }

    public getAgreementPdf(request: GetAgreementPdfRequest): Observable<GetAgreementPdfResponse> {
        return this.post({ getAgreementPdfRequest: request }).pipe(map(res => res.getAgreementPdfResponse));
    }

    public getLastAgreements(request: GetLastAgreementsRequest): Observable<GetLastAgreementsResponse> {
        return this.post({ getLastAgreementsRequest: request }).pipe(map(res => res.getLastAgreementsResponse));
    }

    public getKycProcess(request: GetKycProcessRequest): Observable<GetKycProcessResponse> {
        return this.post({ getKycProcessRequest: request }).pipe(map(res => res.getKycProcessResponse));
    }

    public getUserInvitationRules(request: GetUserInvitationRulesRequest): Observable<GetUserInvitationRulesResponse> {
        return this.post({ getUserInvitationRulesRequest: request }).pipe(map(res => res.getUserInvitationRulesResponse));
    }

    public addBankAccount(request: AddBankAccountRequest): Observable<AddBankAccountResponse> {
        return this.post({ addBankAccountRequest: request }).pipe(map(res => res.addBankAccountResponse));
    }

    public removeBankAccount(request: RemoveBankAccountRequest): Observable<RemoveBankAccountResponse> {
        return this.post({ removeBankAccountRequest: request }).pipe(map(res => res.removeBankAccountResponse));
    }

    public getBankAccount(request: GetBankAccountRequest): Observable<GetBankAccountResponse> {
        return this.post({ getBankAccountRequest: request }).pipe(map(res => res.getBankAccountResponse));
    }

    public getBankAccounts(request: GetBankAccountsRequest): Observable<GetBankAccountsResponse> {
        return this.post({ getBankAccountsRequest: request }).pipe(map(res => res.getBankAccountsResponse));
    }

    public getHelpCenterConfigReport(request: GetHelpCenterConfigReportRequest): Observable<GetHelpCenterConfigReportResponse> {
        return this.post({ getHelpCenterConfigReportRequest: request }).pipe(map(res => res.getHelpCenterConfigReportResponse));
    }

    public getHelpCenterViewProfileConfig(request: GetHelpCenterViewProfileConfigRequest): Observable<GetHelpCenterViewProfileConfigResponse> {
        return this.post({ getHelpCenterViewProfileConfigRequest: request }).pipe(map(res => res.getHelpCenterViewProfileConfigResponse));
    }

    public getUserProfileConfig(request: GetUserProfileConfigRequest): Observable<GetUserProfileConfigResponse> {
        return this.post({ getUserProfileConfigRequest: request }).pipe(map(res => res.getUserProfileConfigResponse));
    }

    public addPoolWithdrawal(request: AddPoolWithdrawalRequest): Observable<AddPoolWithdrawalResponse> {
        return this.post({ addPoolWithdrawalRequest: request }).pipe(map(res => res.addPoolWithdrawalResponse));
    }

    public cancelPoolWithdrawal(request: CancelPoolWithdrawalRequest): Observable<CancelPoolWithdrawalResponse> {
        return this.post({ cancelPoolWithdrawalRequest: request }).pipe(map(res => res.cancelPoolWithdrawalResponse));
    }

    public getPerformancePoolReport(request: GetPerformancePoolReportRequest): Observable<GetPerformancePoolReportResponse> {
        return this.post({ getPerformancePoolReportRequest: request }).pipe(map(res => res.getPerformancePoolReportResponse));
    }

    public getPoolReport(request: GetPoolReportRequest): Observable<GetPoolReportResponse> {
        return this.post({ getPoolReportRequest: request }).pipe(map(res => res.getPoolReportResponse));
    }

    public adminGetPools(request: AdminGetPoolsRequest): Observable<AdminGetPoolsResponse> {
        return this.post({ adminGetPoolsRequest: request }).pipe(map(res => res.adminGetPoolsResponse));
    }

    public getAccountPerformanceReportSummary(request: GetAccountPerformanceReportSummaryRequest): Observable<GetAccountPerformanceReportSummaryResponse> {
        return this.post({ getAccountPerformanceReportSummaryRequest: request }).pipe(map(res => res.getAccountPerformanceReportSummaryResponse));
    }

    public getAccountPerformanceReportSummaries(request: GetAccountPerformanceReportSummariesRequest): Observable<GetAccountPerformanceReportSummariesResponse> {
        return this.post({ getAccountPerformanceReportSummariesRequest: request }).pipe(map(res => res.getAccountPerformanceReportSummariesResponse));
    }

    public getAccountReportSummary(request: GetAccountReportSummaryRequest): Observable<GetAccountReportSummaryResponse> {
        return this.post({ getAccountReportSummaryRequest: request }).pipe(map(res => res.getAccountReportSummaryResponse));
    }

    public getAccountReportSummaries(request: GetAccountReportSummariesRequest): Observable<GetAccountReportSummariesResponse> {
        return this.post({ getAccountReportSummariesRequest: request }).pipe(map(res => res.getAccountReportSummariesResponse));
    }

    public adminGetTargetAccounts(request: AdminGetTargetAccountsRequest): Observable<AdminGetTargetAccountsResponse> {
        return this.post({ adminGetTargetAccountsRequest: request }).pipe(map(res => res.adminGetTargetAccountsResponse));
    }




    public adminDeleteUserInvitationRule(request: AdminDeleteUserInvitationRuleRequest): Observable<AdminDeleteUserInvitationRuleResponse> {
        return this.post({ adminDeleteUserInvitationRuleRequest: request }).pipe(map(res => res.adminDeleteUserInvitationRuleResponse));
    }

    public adminGetUserInvitationRules(request: AdminGetUserInvitationRulesRequest): Observable<AdminGetUserInvitationRulesResponse> {
        return this.post({ adminGetUserInvitationRulesRequest: request }).pipe(map(res => res.adminGetUserInvitationRulesResponse));
    }

    public adminGetUserInvitationRule(request: AdminGetUserInvitationRuleRequest): Observable<AdminGetUserInvitationRuleResponse> {
        return this.post({ adminGetUserInvitationRuleRequest: request }).pipe(map(res => res.adminGetUserInvitationRuleResponse));
    }

    public adminUpdateUserInvitationRule(request: AdminUpdateUserInvitationRuleRequest): Observable<AdminUpdateUserInvitationRuleResponse> {
        return this.post({ adminUpdateUserInvitationRuleRequest: request }).pipe(map(res => res.adminUpdateUserInvitationRuleResponse));
    }

    public adminAddUserInvitationRule(request: AdminAddUserInvitationRuleRequest): Observable<AdminAddUserInvitationRuleRequest> {
        return this.post({ adminAddUserInvitationRuleRequest: request }).pipe(map(res => res.adminAddUserInvitationRuleResponse));
    }
    
    
    

    public adminDeleteFx8Account(request: AdminDeleteFx8AccountRequest): Observable<AdminDeleteFx8AccountResponse> {
        return this.post({ adminDeleteFx8AccountRequest: request }).pipe(map(res => res.adminDeleteFx8AccountResponse));
    }

    public adminGetFx8Accounts(request: AdminGetFx8AccountsRequest): Observable<AdminGetFx8AccountsResponse> {
        return this.post({ adminGetFx8AccountsRequest: request }).pipe(map(res => res.adminGetFx8AccountsResponse));
    }

    public adminGetFx8Account(request: AdminGetFx8AccountRequest): Observable<AdminGetFx8AccountResponse> {
        return this.post({ adminGetFx8AccountRequest: request }).pipe(map(res => res.adminGetFx8AccountResponse));
    }

    public adminUpdateFx8Account(request: AdminUpdateFx8AccountRequest): Observable<AdminUpdateFx8AccountResponse> {
        return this.post({ adminUpdateFx8AccountRequest: request }).pipe(map(res => res.adminUpdateFx8AccountResponse));
    }

    public adminAddFx8Account(request: AdminAddFx8AccountRequest): Observable<AdminAddFx8AccountRequest> {
        return this.post({ adminAddFx8AccountRequest: request }).pipe(map(res => res.adminAddFx8AccountResponse));
    }


    public adminDeleteUser(request: AdminDeleteUserRequest): Observable<AdminDeleteUserResponse> {
        return this.post({ adminDeleteUserRequest: request }).pipe(map(res => res.adminDeleteUserResponse));
    }

    public adminGetUsers(request: AdminGetUsersRequest): Observable<AdminGetUsersResponse> {
        return this.post({ adminGetUsersRequest: request }).pipe(map(res => res.adminGetUsersResponse));
    }

    public adminGetUser(request: AdminGetUserRequest): Observable<AdminGetUserResponse> {
        return this.post({ adminGetUserRequest: request }).pipe(map(res => res.adminGetUserResponse));
    }

    public adminUpdateUser(request: AdminUpdateUserRequest): Observable<AdminUpdateUserResponse> {
        return this.post({ adminUpdateUserRequest: request }).pipe(map(res => res.adminUpdateUserResponse));
    }

    public adminAddUser(request: AdminAddUserRequest): Observable<AdminAddUserRequest> {
        return this.post({ adminAddUserRequest: request }).pipe(map(res => res.adminAddUserResponse));
    } 
    
    

    public getUserProfile(request: GetUserProfileRequest): Observable<GetUserProfileResponse> {
        return this.post({ getUserProfileRequest: request }).pipe(map(res => res.getUserProfileResponse));
    }

    public adminSetImpersonateUser(request: AdminSetImpersonateUserRequest): Observable<AdminSetImpersonateUserResponse> {
        return this.post({ adminSetImpersonateUserRequest: request }).pipe(map(res => res.adminSetImpersonateUserResponse));
    }

    public adminGetImpersonateUser(request: AdminGetImpersonateUserRequest): Observable<AdminGetImpersonateUserResponse> {
        return this.post({ adminGetImpersonateUserRequest: request }).pipe(map(res => res.adminGetImpersonateUserResponse));
    }

    public adminDeleteUserProfile(request: AdminDeleteUserProfileRequest): Observable<AdminDeleteUserProfileResponse> {
        return this.post({ adminDeleteUserProfileRequest: request }).pipe(map(res => res.adminDeleteUserProfileResponse));
    }

    public adminUpdateUserProfile(request: AdminUpdateUserProfileRequest): Observable<AdminUpdateUserProfileResponse> {
        return this.post({ adminUpdateUserProfileRequest: request }).pipe(map(res => res.adminUpdateUserProfileResponse));
    }

    public adminAddUserProfile(request: AdminAddUserProfileRequest): Observable<AdminAddUserProfileRequest> {
        return this.post({ adminAddUserProfileRequest: request }).pipe(map(res => res.adminAddUserProfileResponse));
    }

    public adminGetUserProfile(request: AdminGetUserProfileRequest): Observable<AdminGetUserProfileResponse> {
        return this.post({ adminGetUserProfileRequest: request }).pipe(map(res => res.adminGetUserProfileResponse));
    }

    public adminGetUserProfiles(request: AdminGetUserProfilesRequest): Observable<AdminGetUserProfilesResponse> {
        return this.post({ adminGetUserProfilesRequest: request }).pipe(map(res => res.adminGetUserProfilesResponse));
    }

    public adminGetConfig(request: AdminGetConfigRequest): Observable<AdminGetConfigResponse> {
        return this.post({ adminGetConfigRequest: request }).pipe(map(res => res.adminGetConfigResponse));
    }

    public adminGetConfigs(request: AdminGetConfigsRequest): Observable<AdminGetConfigsResponse> {
        return this.post({ adminGetConfigsRequest: request }).pipe(map(res => res.adminGetConfigsResponse));
    }

    public adminDeleteConfig(request: AdminDeleteConfigRequest): Observable<AdminDeleteConfigResponse> {
        return this.post({ adminDeleteConfigRequest: request }).pipe(map(res => res.adminDeleteConfigResponse));
    }

    public adminCreateConfig(request: AdminCreateConfigRequest): Observable<AdminCreateConfigResponse> {
        return this.post({ adminCreateConfigRequest: request }).pipe(map(res => res.adminCreateConfigResponse));
    }

    public adminGetFieldDataSourceSet(request: AdminGetFieldDataSourceSetRequest): Observable<AdminGetFieldDataSourceSetResponse> {
        return this.post({ adminGetFieldDataSourceSetRequest: request }).pipe(map(res => res.adminGetFieldDataSourceSetResponse));
    }

    public adminGetAppConfigSchema(request: AdminGetAppConfigSchemaRequest): Observable<AdminGetAppConfigSchemaResponse> {
        return this.post({ adminGetAppConfigSchemaRequest: request }).pipe(map(res => res.adminGetAppConfigSchemaResponse));
    }

    public adminUpdateConfig(request: AdminUpdateConfigRequest): Observable<AdminUpdateConfigResponse> {
        return this.post({ adminUpdateConfigRequest: request }).pipe(map(res => res.adminUpdateConfigResponse));
    }

    public getSimulationAccount(request: GetSimulationAccountRequest): Observable<GetSimulationAccountResponse> {
        return this.post({ getSimulationAccountRequest: request }).pipe(map(res => res.getSimulationAccountResponse));
    }

    public getStrategyInfoList(request: GetStrategyInfoListRequest): Observable<GetStrategyInfoListResponse> {
        return this.post({ getStrategyInfoListRequest: request }).pipe(map(res => res.getStrategyInfoListResponse));
    }

    public echo(request: EchoRequest): Observable<EchoResponse> {
        return this.post({ echoRequest: request }).pipe(map(res => res.echoResponse));
    }

    public hello(request: HelloRequest): Observable<HelloResponse> {
        return this.post({ helloRequest: request }).pipe(map(res => res.helloResponse));
    }

    private post(request: ApiRequest): Observable<ApiResponse> {
        return this.apiSupportService.post(environment.apiUrl + '/api-handler', request);
    }
}
