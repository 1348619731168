import {Injectable} from '@angular/core';
import {DialogService} from "./dialog.service";
import {first} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";


const httpOptions = {
    headers: new HttpHeaders({
        'Cache-Control': 'no-cache'
    })
};

@Injectable({
    providedIn: 'root'
})
export class SpaUpdateService {

    lastDeployCode: string = null;
    checkDeployCodeEnabled = true;
    timeout = 10 * 60_000;
    // timeout = 10_000;

    constructor(
        private httpClient: HttpClient,
        private dialogService: DialogService) {
        console.log('init SpaUpdateService');
        this.checkDeployCode(0);
    }

    private checkDeployCode(timeout: number) {
        if (this.checkDeployCodeEnabled) {
            setTimeout(() => {
                const res$ = this.httpClient.get('assets/general/deploy-code.json', httpOptions).pipe(first());
                res$.subscribe({
                    next: (data: any) => {
                        const deployCode = data.deployCode.toString();
                        if (this.lastDeployCode !== null && this.lastDeployCode !== deployCode) {
                            this.checkDeployCodeEnabled = false;
                            this.dialogService.information('home.common.refresh-page-info').subscribe(_ => {
                                window.location.reload();
                            });
                        }
                        this.lastDeployCode = deployCode;
                        console.log('deploy code: ' + data.deployCode.toString());
                        this.checkDeployCode(this.timeout);
                    },
                    error: err => {
                        console.log('error on spa update check: ' + JSON.stringify(err));
                    }
                });
            }, timeout);
        }
    }
}

