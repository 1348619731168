import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';


import {Observable, switchMap} from 'rxjs';

import {environment} from 'environments/environment';
import {CognitoAuthService} from "../core/cognito-auth/cognito-auth.service";

@Injectable({
    providedIn: 'root'
})
export class Fx8ApiInterceptor implements HttpInterceptor {

    constructor(private authService: CognitoAuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.startsWith(environment.apiUrl)) {
            return this.authService.getAccessToken().pipe(
                switchMap(accessToken => {
                    const req = request.clone({
                        setHeaders: {
                            Authorization: accessToken
                        }
                    });
                    return next.handle(req);
                })
            );
        }
        else {
            return next.handle(request);
        }
    }

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     if (request.url.startsWith(environment.apiUrl)) {
    //         return this.authService.getUser().pipe(
    //             switchMap(user => {
    //                 const req = request.clone({
    //                     setHeaders: {
    //                         Authorization: user.accessToken
    //                     }
    //                 });
    //                 return next.handle(req);
    //             })
    //         );
    //     }
    //     else {
    //         return next.handle(request);
    //     }
    // }
}
